import React from "react";
import { Spring } from 'react-spring/renderprops';
import { graphql, StaticQuery } from "gatsby";

import { css } from '@emotion/react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { isServer } from "../layouts/layoutDefault";

export const ImagenCopertina = ({ sorgente }) => (
	<GatsbyImage
				alt="hero-image"
        image={sorgente}
        css={css`
			height: 34vh;
			box-shadow: 0px 3px 10px rgba(25, 17, 24, 0.3);
				@media screen and (max-width: 500px) {
					z-index: 99;
					height: 28vh;
				}
		`} />
);

/* GRAPHQL QUERY FOR DEFAULT EXPORT */
const MAIN_HERO_QUERY = graphql`query MainHeroQuery {
  file(relativePath: {regex: "/riot-firefighter-home/"}) {
    childImageSharp {
      gatsbyImageData(quality: 95, placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
}
`


/* DEFAULT EXPORT */
const SpringHero = ({ location }) => {
const nellaHome = location.pathname === '/' || /\/index\/(?:[2-9]|\d{2,})/.test(location.pathname); // nella home o nelle sue diverse pagine

return (
    <StaticQuery
		query={MAIN_HERO_QUERY}
		render={data => (
				<Spring
					from={{ height: '0vh'}}
				to={{ height: isServer ? '0vh' : window.innerWidth <= 500 ? '0vh' : (nellaHome ? '36vh' : '0vh')}}
				>
					{({ height }) =>
						<section style={{ overflow: 'hidden', height }}>
							<ImagenCopertina 
								sorgente={ getImage(data.file) } 
							/>
						</section>
					}
				</Spring>
		)}
	/>
);}
export default SpringHero