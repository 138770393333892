import React from "react";
import styled from "@emotion/styled"

const IndexFloat = ({ indx, children }) => (
<IndexAside>
	<div className="orderedlist">
		{ children ?? <div>
			<h3>Indice:</h3>
			<ol>
				{indx.map((item, index) => (
					<li key={index}>
						<a href={item.anchor}>{item.label}</a>
						{item.submenu && (
							<ul>
								{item.submenu.map((subItem,subIndx) => (
									<li key={subIndx} ><a href={subItem.anchor}>{subItem.label}</a></li>
								))}
							</ul>
						)}
					</li>
				))}
			</ol>
		</div>
		}
	</div>
</IndexAside>
)

/* Style Indice Post */

const IndexAside = styled.aside`
display: none;
position: absolute;
left: 3rem;
top: 23.5rem;
height: 1150%;

.orderedlist {
  font-size: 0.7rem;
  position: sticky;
	top: 4.5rem;
	left: 0;
}

.orderedlist > ol > li,
.orderedlist > ol > li > ul > li {
	margin-bottom: 10px;
}
.orderedlist > ol > li > ul > li:last-child {
	margin-bottom: 15px;
}
.orderedlist > ol > li > ul {
	margin-left: 1rem;
}

@media (min-width: 1200px) {
	display: block;
}
@media (min-width: 1400px) {
	left: calc(2rem + 10vw);
}
`

export { IndexFloat as default }