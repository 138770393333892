import React from "react";
import styled from "@emotion/styled"
import {Link} from "gatsby";
import SwitchButton from "./buttons/switchButton";


const ULMenu = styled.ul`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	margin: 0;
	
	> li {
		text-decoration: none;
		list-style: none;
		margin: 0;

		color: antiquewhite;
		font-weight: 600;
		
		padding: 0 20px;

		> a {
				color: antiquewhite;
				text-decoration: none;
			}

		@media (max-width: 769px) {font-size: 16px;}

	}

	@media (max-width: 500px) {
		margin: auto;
			> li {
				padding: 0;
				> a { padding: 10px 7px; margin: 0 5px; }
			}
		/* Viene disabilitato da mobile solo quando la classe parent contiene "HeaderContainer" (inserita nel suo styled component tramite un label).
			Quindi disabilitata solo quando è nel header sopra. 
			Sarebbe come dire:
			.eccecc-HeaderContainer .ULMenu { display: none }

			[class*="HeaderContainer"] & { display: none; }
		*/
		
	}
`
const Nav = styled.nav`
/* margin auto quando è in modalità mobile */
	@media (max-width: 500px) {
			margin: auto;
		[class*="HeaderContainer"] & { display: none; }
	}
`


const ListaMenu = () => (
	<Nav>
		<ULMenu className="lista-header">
			<li><Link to='/'>Home</Link></li>
			<li><Link to='/about'>About</Link></li>
			<li><Link to='/p/questo-blog-e-un-luogononsense'>Disclaimer</Link></li>
			<li><SwitchButton /></li>
		</ULMenu>
	</Nav>
)
export default ListaMenu