/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { ThemeProvider } from "./src/context/ThemeContext"

// IMPORTING GLOBAL CSS FOR MY CSS GRID
//import "./src/utils/layout.css"
import "./src/utils/global.css"


export const wrapRootElement = ({ element }) => (
	<ThemeProvider>{element}</ThemeProvider>
)

/* // SAVE POSITION WHEN USER COMES BACK TO THE LAST PAGE
const transitionDelay = 300

module.shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	if (location.action === 'PUSH') {
		window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
	} else {
		const savedPosition = getSavedScrollPosition(location)
		window.setTimeout(
			() => window.scrollTo(...(savedPosition || [0, 0])),
			transitionDelay
		)
	}
	return false
} */

/* export const onServiceWorkerUpdateReady = () => {
	const answer = window.confirm(
		`Il sito è stato aggiornato. ` +
		`Vuoi caricare la versione più aggiornata?`
	)
	if (answer === true) {
		window.location.reload()
	}
} */