import React from 'react'
import { Link } from "gatsby";
import styled from '@emotion/styled'
import { FaTwitter, /* FaTelegram, FaTumblr, FaGooglePlus,FaRssSquare, */ FaRss, FaTelegramPlane } from 'react-icons/fa'

const FooterWrapper = styled.footer`
	position: relative;
	width: 100%;
	background: #524763;
	@media (min-width: 500px ){ z-index: 98; }
`
const Footer = styled.div`
	
	display: grid; 
	grid-template-columns: 1fr 1fr;
	max-width: 1200px;
	margin: 0 auto;
	padding: 1.5rem;
	grid-column-gap: 3em;
	grid-row-gap: 2em;
	color: #fff;

		> div > h3 {
			margin-bottom: 0.68rem;
			color: #fff;
		}
		> div > a {color: #ff9000;}
		ul, li {
			margin: 0;
			text-decoration: none;
			list-style: none;
		}
		.lista-social-footer {
			grid-column: 1 / -1;
			display: flex;
			justify-content: space-around;
			font-size: 1.5rem;
			> li > a > svg {fill: #fff}

			/* Con questo annullo il justify content (poiché il margin auto orizzontale mette tutto insieme)  */
			margin: 0 auto;
			li {
			/* E con questo imposto la distanza a 2.5em per ogni elemento */
				margin: 0 2.5em;
			}
		}
		@media (max-width: 720px) {
			grid-template-columns: 1fr;
			/* torno ad avere le icona social con justify-content: space-around; */
			.lista-social-footer{
				margin: 0;
				li {
					margin: 0;
				}
			}
		}
		/* Style dei box col testo nel footer */
		> div{
			padding: 0 1.2rem;
			font-size: 0.8rem;
			a {color: #f9a600;}
			@media (max-width: 1000px) {font-size: 0.7rem;}
		}

`

const FooterComponent = () => (

	<FooterWrapper className="footer-wrapper">

		<Footer>
			<ul className="lista-social-footer">
				<li><a href="http://feeds.feedburner.com/anonimoconiglio" target="_blank" rel="noopener noreferrer" aria-label="feed-reader"><FaRss /></a></li>
{/* 				<li><a href="http://cloud.feedly.com/#subscription%2Ffeed%2Fhttp%3A%2F%2Ffeeds.feedburner.com%2Fanonimoconiglio" target="_blank"><FaRssSquare />Feedly</a></li>
				<li><a href="https://plus.google.com/100466170970927492776" rel="publisher" target="_blank"><FaGooglePlus /></a></li>
				<li><a href="http://anonimoconiglio.tumblr.com/" target="_blank"><FaTumblr /></a></li> */}
				<li><a href="https://twitter.com/anonimoconiglio" target="_blank" rel="noopener noreferrer" aria-label="twitter"><FaTwitter /></a></li>
				<li><a href="https://telegram.me/anonimoconiglio" target="_blank" rel="noopener noreferrer" aria-label="twitter"><FaTelegramPlane /></a></li>
			</ul>

			<div>
				<h3>Note Legali</h3>
				Questo blog <i>non rappresenta una testata giornalistica, in quanto viene aggiornato senza alcuna periodicità. Non può, pertanto, considerarsi un prodotto editoriale, ai sensi della <a href="http://www.camera.it/parlam/leggi/01062l.htm">legge n. 62 del 7/03/2001</a></i> <br />
				Le pareti, al suo interno, sono rivestite di <strong>Diritto di Critica</strong> e <strong>Diritto di Satira</strong>, maneggiare con cura. Consultare <Link to="/p/questo-blog-e-un-luogononsense">INFO - Disclaimer</Link>
			</div>

			<div>
				<h3>Licenza</h3>
				(CC BY-NC-SA 3.0) | <span dc="http://purl.org/dc/elements/1.1/" property="dc:title">anonimoconiglio.com</span> by <span cc="http://creativecommons.org/ns#" property="cc:attributionName">Santiago Greco</span>. <br/> 
				This blog is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/3.0/">Creative Commons Attribution-NonCommercial-ShareAlike 3.0 Unported License</a>.
			</div>
		</Footer>
	</FooterWrapper >
)
export default FooterComponent