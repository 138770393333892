import React from "react";
import { Link } from "gatsby";
import styled from '@emotion/styled';

import LogoSvg from '../images/svg/logo-anonimo-2.svg'; 
import ULMenu from "../components/listaMenu";

/* 
// Alternativa, usando una <img> normale e importando como "logo":
import logo, { ReactComponent as LogoSvg } from '../images/logo-anonimo-2.svg'
	<img src={logo} alt="Level Up Tutorials" />  
*/ 

const HeaderWrapper = styled.div`
  background: ${props => props.transparent ? `transparent` : '#524763' };
  position: sticky;
  top: 0;
  width: 100%;
  margin-bottom: 0;
  z-index: 2;

		@media (max-width: 500px) {
			position: unset;
		}
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1200px;
  margin: 0 auto;
  padding: 0.1rem;

  padding-left: 2.5rem;

  h1 {
    margin: 0;
  }

	> a:focus { outline: none; }

  @media (max-width: 1199px) {
    max-width: 960px;
  }

	/* FOR MOBILE */
		@media (max-width: 500px) {
			padding-left: 0;
			> a {margin: auto;}
				.logo-blog {
					padding-right:0;
					padding-top: 0px;
					padding-bottom: 0.1rem;
					margin-top: 10px;
					height: 30px;
				}
		}
`;

const Logo = styled(LogoSvg)`
		margin-bottom: 0;
    height:38px;
    fill: antiquewhite;
    padding-top: 10px;
`

const Header = ({ ulmenuvisibile, transparent }) => {
	return (
  <HeaderWrapper transparent={transparent} className="barra-header-superiore">
    <HeaderContainer className="HeaderContainer">

      <Link to="/">
					<Logo />
      </Link>

			{ ulmenuvisibile && <ULMenu /> }

    </HeaderContainer>
  </HeaderWrapper>
)};

Header.defaultProps = {
	ulmenuvisibile: true,
	transparent: false
}

export default Header;
