import React from "react";
import ThemeContext from "../../context/ThemeContext"
import { css } from '@emotion/react';



const cssbottone = css`
.switch { position: relative; }
.switch input {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
}
.switch label {
  height: 34px;
  width: 84px;
  background-color: #39315a;
  border-radius: 100px;
  display: block;
  box-shadow: inset 0 0 20px rgba(0,0,0,0.2), inset 0 0 5px -2px rgba(0,0,0,0.4);
}
.switch label .bulb {
  height: 14px;
  width: 14px;
  background-color: #4a426b;
  border-radius: 50%;
  position: relative;
  top: 10px;
  left: 10px;
  display: block;
  transition: 0.7s;
  box-shadow: inset 0 0 1px 3px #4a426b, inset 0 0 6px 8px #423963, 0 20px 30px -10px rgba(0,0,0,0.4);
}
.switch label .bulb .bulb-center {
  position: absolute;
  display: block;
  height: 28px;
  width: 28px;
  background-color: #5a527b;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transition: 0.7s;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: inset 0 0 0 4px #635a84;
}
.switch label .bulb .bulb-center:after {
  content: "";
  display: block;
  height: 14px;
  width: 14px;
  background-color: #7b7394;
  border-radius: 50%;
  position: absolute;
  transition: 0.7s;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0 0 2px 4px #524a73;
}
.switch label .bulb .filament-1,
.switch label .bulb .filament-2 {
  position: absolute;
  display: block;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  overflow: hidden;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}
.switch label .bulb .filament-1:after,
.switch label .bulb .filament-2:after,
.switch label .bulb .filament-1:before,
.switch label .bulb .filament-2:before {
  content: "";
  display: block;
  height: 6px;
  width: 17px;
  border-radius: 50%;
  border: 2px solid #4a426b;
  position: absolute;
  transition: 0.7s;
  top: -4px;
  left: -2px;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}
.switch label .bulb .filament-1:before,
.switch label .bulb .filament-2:before {
  left: 15px;
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
}
.switch label .bulb .filament-2 {
  -webkit-transform: translate(-50%, -50%) rotate(45deg) !important;
          transform: translate(-50%, -50%) rotate(45deg) !important;
}
.reflections {
  height: 100%;
  width: 100%;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  z-index: 90;
  -webkit-perspective: 70px;
          perspective: 70px;
}
.reflections span {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-image: linear-gradient(-135deg, transparent 10%, rgba(255,255,255,0.3));
  position: absolute;
  left: -40px;
  bottom: -45px;
}
.reflections span:after {
  content: "";
  display: block;
  height: 35px;
  width: 20px;
  position: absolute;
  top: -36px;
  right: -40px;
  border-radius: 50%;
  box-shadow: 4px -2px 0 -3px rgba(255,255,255,0.4);
  -webkit-filter: blur(1px);
          filter: blur(1px);
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}
.reflections:after {
  content: "";
  display: block;
  height: 80px;
  width: 50px;
  background-image: linear-gradient(80deg, rgba(255,255,255,0.05) 45%, rgba(255,255,255,0.5));
  border-radius: 10% 20% 50% 30%/30% 60% 30% 40%;
  position: absolute;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: rotateX(-25deg) rotate(-35deg) skewx(-15deg) translate(10px, -20px);
          transform: rotateX(-25deg) rotate(-35deg) skewx(-15deg) translate(10px, -20px);
  top: -8px;
  left: -5px;
}
.reflections:before {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 30px;
  background-image: linear-gradient(to right, transparent, rgba(255,255,255,0.15));
  bottom: 10px;
  right: 0px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.sparks .spark1 {
  display: block;
  height: 1px;
  width: 1px;
  background-color: #d1b82b;
  position: absolute;
  right: -5px;
  border-radius: 50%;
  bottom: 23px;
  transition: 0.4s;
  opacity: 0;
}
.sparks .spark2 {
  display: block;
  height: 3px;
  width: 3px;
  background-color: #d1b82b;
  position: absolute;
  right: 20px;
  border-radius: 50%;
  bottom: 80px;
  transition: 0.4s;
  opacity: 0;
}
.sparks .spark3 {
  display: block;
  height: 3px;
  width: 3px;
  background-color: #d1b82b;
  position: absolute;
  left: 20px;
  border-radius: 50%;
  bottom: 80px;
  transition: 0.4s;
  opacity: 0;
}
.sparks .spark4 {
  display: block;
  height: 3px;
  width: 3px;
  background-color: #d1b82b;
  position: absolute;
  left: 20px;
  border-radius: 50%;
  bottom: 20px;
  transition: 0.4s;
  opacity: 0;
}
.switch input:checked ~ label .bulb {
  left: 60px;
  background-color: #a7694a;
  box-shadow: inset 0 0 1px 3px #a56758, inset 0 0 6px 8px #6b454f, 0 20px 30px -10px rgba(0,0,0,0.4), 0 0 30px 50px rgba(253,184,67,0.1);
}
.switch input:checked ~ label .bulb > .bulb-center {
  background-color: #feed6b;
  box-shadow: inset 0 0 0 4px #fdec6a,0 0 8px 5px #bca83c,0 0 8px 8px #a1664a;
}
.switch input:checked ~ label .bulb > .bulb-center:after {
  background-color: #fef401;
  box-shadow: 0 0 2px 4px #fdb843;
}
.switch input:checked ~ label .bulb >.filament-1:before,
.switch input:checked ~ label .bulb >.filament-2:before,
.switch input:checked ~ label .bulb >.filament-1:after,
.switch input:checked ~ label .bulb >.filament-2:after {
  border-color: #fef4d5;
}
.switch input:checked ~ label .bulb > .sparks .spark1 {
  height: 1px;
  width: 1px;
  -webkit-animation: spark1 2s ease-in-out;
          animation: spark1 2s ease-in-out;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.switch input:checked ~ label .bulb > .sparks .spark2 {
  height: 1px;
  width: 1px;
  -webkit-animation: spark2 2.4s ease-in-out;
          animation: spark2 2.4s ease-in-out;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.switch input:checked ~ label .bulb > .sparks .spark3 {
  height: 1px;
  width: 1px;
  -webkit-animation: spark3 2s ease-in-out;
          animation: spark3 2s ease-in-out;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
.switch input:checked ~ label .bulb > .sparks .spark4 {
  height: 1px;
  width: 1px;
  -webkit-animation: spark4 1.7s ease-in-out;
          animation: spark4 1.7s ease-in-out;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
@-webkit-keyframes spark1 {
  0% {
    right: -5px;
    height: 1px;
    width: 1px;
    opacity: 0;
  }
  20% {
    height: 3px;
    width: 3px;
    right: 0px;
    opacity: 1;
  }
  30% {
    right: -5px;
    opacity: 1;
    height: 3px;
    width: 3px;
  }
  70% {
    height: 3px;
    width: 3px;
  }
  100% {
    right: -60px;
    bottom: 40px;
    opacity: 0;
  }
}
@keyframes spark1 {
  0% {
    right: -5px;
    height: 1px;
    width: 1px;
    opacity: 0;
  }
  20% {
    height: 3px;
    width: 3px;
    right: 0px;
    opacity: 1;
  }
  30% {
    right: -5px;
    opacity: 1;
    height: 3px;
    width: 3px;
  }
  70% {
    height: 3px;
    width: 3px;
  }
  100% {
    right: -60px;
    bottom: 40px;
    opacity: 0;
  }
}
@-webkit-keyframes spark2 {
  0% {
    height: 3px;
    width: 3px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    right: -20px;
    bottom: 100px;
    opacity: 0;
  }
}
@keyframes spark2 {
  0% {
    height: 3px;
    width: 3px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    right: -20px;
    bottom: 100px;
    opacity: 0;
  }
}
@-webkit-keyframes spark3 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
    height: 2px;
    width: 2px;
  }
  100% {
    left: 0px;
    bottom: 100px;
    opacity: 0;
    height: 3px;
    width: 3px;
  }
}
@keyframes spark3 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
    height: 2px;
    width: 2px;
  }
  100% {
    left: 0px;
    bottom: 100px;
    opacity: 0;
    height: 3px;
    width: 3px;
  }
}
@-webkit-keyframes spark4 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
    height: 2px;
    width: 2px;
  }
  100% {
    left: -20px;
    bottom: -10px;
    opacity: 0;
    height: 3px;
    width: 3px;
  }
}
@keyframes spark4 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
    height: 2px;
    width: 2px;
  }
  100% {
    left: -20px;
    bottom: -10px;
    opacity: 0;
    height: 3px;
    width: 3px;
  }
}
`


const SwitchButton = () => (
	<ThemeContext.Consumer>
		{theme => (
			<>
				{/*
				<DarkSwitcher className="dark-switcher" onClick={theme.toggleDark}>
					{theme.dark ? <span>☀</span> : <span>☾</span>}
				</DarkSwitcher> 
				*/}
				<div css={cssbottone}>
					<div className="switch">
						{theme.dark ? (
							<input type="checkbox" onChange={theme.toggleDark} name="toggle" checked />
						) : (
							<input type="checkbox" onChange={theme.toggleDark} name="toggle" checked={false} />
						) }
						<label htmlFor="toggle">
							<i className="bulb">
								<span className="bulb-center"></span>
								<span className="filament-1"></span>
								<span className="filament-2"></span>
								<span className="reflections">
									<span></span>
								</span>
								<span className="sparks">
									<i className="spark1"></i>
									<i className="spark2"></i>
									<i className="spark3"></i>
									<i className="spark4"></i>
								</span>
							</i>
						</label>
					</div>

				</div>

			</>
		)}
	</ThemeContext.Consumer>
)

/* Dark Switch DEPRECATED 
const DarkSwitcher = styled.button`
  color: #fff;
  border-radius:17px;
	border:1px solid #1f2f47;
  cursor: pointer;
	background:linear-gradient(to bottom, #2e466e 5%, #415989 100%);

	.light & {background: linear-gradient(to bottom, #692e6ea3 5%, #414289 100%);}
	.dark & {background: linear-gradient(to bottom, #a09e3f 5%, #805d8c 100%);}
	`
*/
export default SwitchButton