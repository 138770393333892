import { useState, useEffect } from 'react'

export const useSetButtonVisibleAt = altezza => {

	const goTo = referPoint => {
		referPoint
			? referPoint.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
			: window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const [ isVisible, setState ] = useState(false)

	useEffect(() => {

		const changeStatus = () => window.pageYOffset >= altezza ? setState(true) : setState(false)

		window.addEventListener("scroll", changeStatus)
		return () => window.removeEventListener("scroll", changeStatus)

	}, [altezza]);

	return { isVisible, goTo }
}