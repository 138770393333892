import React from "react";
import PropTypes from "prop-types";
import Springhero from "../components/springhero";
import Header from "./header.js";
import MenuMobile from "../components/menuMobile";
import Footer from "./footer";
import ThemeContext from "../context/ThemeContext";
import BottoneGoUp from "../components/buttons/buttonScrollUp"
import CookieBanner from "../components/cookieBanner"

export const isServer = typeof window === 'undefined';

// import "../utils/global.css" // Global CSS viene caricato tramite gatsby-browser.js

const LayoutDefault = ({ children, location }) => {

	const isMobile = isServer ? false : window.innerWidth <= 500

	return (
  
		<ThemeContext.Consumer>
			{theme => (
				<div className={theme.dark ? 'dark' : 'light'}>
					
					<Header ulmenuvisibile={ isMobile ? false : true } />

					{ isMobile ? null : <Springhero location={location} /> }

        		{children}

					<BottoneGoUp />

					{ isServer || location.pathname === '/cookie-policy' ? null : <CookieBanner /> }

					<Footer />

					{ isMobile ? <MenuMobile /> : null }

				</div>
			)}
    </ThemeContext.Consumer>
)};

LayoutDefault.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutDefault