/* 
	Questo file sarà considerato una sorta di index
	Se il layout non è speciale (regola dentro "node") userà il layoutDefault.
*/
import React from "react";
import LayoutDefault from './layoutDefault'

const MainIndexFork = ({ children, location, pageContext }) => {
	if (location.pathname === '/offline-plugin-app-shell-fallback/') return null
	const { layout } = pageContext

	if (layout === "special") return <div>{ children }</div>

	return (
			<LayoutDefault location={location}>
					<>{ children }</>
			</LayoutDefault>)
}


export default MainIndexFork