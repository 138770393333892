import React, { useState } from 'react'
import styled from '@emotion/styled'


const CookieWrapper = () => {
	//const cookie_esiste = document.cookie.match(/^(.*;)?\s*cookie_di_merda_gdpr\s*=\s*[^;]+(.*)?$/)

	const leggiCookie = () => {
		let cookieArray = document.cookie.split(';')
		const cookieRetreived = cookieArray.find(cookie => {
			let nameToSearch = new RegExp('cookie_di_merda_gdpr', 'g')
			return cookie.match(nameToSearch)
		})

		const checkCookieExistence = () => Boolean(cookieRetreived)
		const checkCookieValue = () => Boolean(cookieRetreived?.match(/true/))

		return {
			cookieExist: checkCookieExistence(),
			value: checkCookieValue()
		}
	}

	return !leggiCookie().cookieExist 
		? <CookieBanner /> 
		: null
}

const CookieBanner = () => {

	const [ showCookie, setShowCookie ] = useState(true)
	const [ showDialog, setShowDialog ] = useState(false)
	const [ userChoice, setUserChoice ] = useState(false)

	/* SET COOKIE FOR GDPR */
	const setCookieLaw = cookieValue => {
		const monthsFromNow = nMonths => {
			let today = new Date()
			let monthsFromNow = new Date( today.setMonth( today.getMonth() + nMonths ) )
			return monthsFromNow.toUTCString()
		}
		document.cookie = `cookie_di_merda_gdpr=${cookieValue};expires=${monthsFromNow(11)};path=/;sameSite=Strict;`
		setShowCookie(false)
	}

	const handleSelection = () => userChoice ? setCookieLaw(true) : setCookieLaw(false)
	
	return showCookie ? (
			<CookieMessage>
				<InnerBox>
					<p className="text">
						Anonimoconiglio utilizza cookie (propri o di terze parti) per fini statistici e per migliorare l’interazione con le piattaforme social media (<strong>oltre a vendere i tuoi segreti più reconditi a Soros, ovviamente</strong>). Va bene?
				</p>
					<Buttons>

						<BtnCustomize onClick={() => setShowDialog(true)}>Voglio personalizzare</BtnCustomize>

						<BtnAcceptAll onClick={() => setCookieLaw(true)}>Massì accetto</BtnAcceptAll>

						{showDialog && (
						<ChoiceBox>
							<List>
								<Item><Input type="checkbox" checked disabled /> &nbsp; <Label>Strettamente necessari</Label></Item>
								<Item><Input id="user-choice" type="checkbox" onChange={ event => setUserChoice(event.target.checked)} /> &nbsp; <Label htmlFor="user-choice">Misurazione traffico</Label></Item>
							</List>
							<BtnAcceptSelection onClick={handleSelection}>Accetta Selezione</BtnAcceptSelection>
							<CloseIcon onClick={() => setShowDialog(false)} />
						</ChoiceBox>)}

					</Buttons>
				</InnerBox>
			</CookieMessage>

		) : null
}
export default CookieWrapper



const InnerBox = styled.div`
	margin: auto;

	background-color: whitesmoke;
	padding: 20px 25px;

	border: 1px solid #333; 
	border-radius: 5px;

	width: 80vw;
	@media ( min-width: 500px ) {
		width: 45vw;
		max-width: 560px;
	}

	font-size: 0.8rem;
	@media (min-width: 500px) { font-size: 0.9rem; }
	p { 
		margin: 0 auto 1em;
	}
`

const Buttons = styled.div`
	--blue: #524763;
	--green: #02B201;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1em;
	
	@media ( max-width: 720px) {
		flex-direction: column;
	}

`

const ChoiceBox = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	padding: 10px 20px;
	border: 1px solid transparent; 
	border-radius: 5px;
	box-shadow: 2px 2px 6px rgba(0,0,0, .4);
	background: whitesmoke;

	align-items: center; 
	gap: 1.5em;

	display: flex;
	@media ( max-width: 720px) {
		flex-direction: column;
		gap: 0.2em;
	}
	/* transition: all ease-out 400ms;
	visibility: hidden;
	opacity: 0; */
`
const List = styled.ul`
	list-style: none;
	padding: 0;
	margin-bottom: 0;
	margin-left: 0;
`
const Item = styled.li`
	line-height: 2em;
	font-size: 0.7rem;
`
const Input = styled.input`
	cursor: pointer;
	vertical-align: text-bottom;
`
const Label = styled.label` cursor: pointer; `

const CloseIcon = styled.div`
	--close-icon: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgb(255, 56, 96)" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg>') , radial-gradient( circle, white 0%, rgba(255,255,255, 0.2) 40%, rgba(0,0,0,0) 100%);
	--size: 25px;
	--offset: calc(var(--size) / 3.5 * -1 );
	position: absolute;
	top: var(--offset);
	right: var(--offset);

	background-image: var(--close-icon);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: var(--size);
	width: var(--size);
	border: none;
	padding: 0;
	cursor: pointer;
	z-index: 999;
`

const Btn = styled.button`
	border: 1px solid transparent;
	border-radius: 5px;
	// box-shadow: 2px 2px 6px rgba(120, 128, 139, 0.3);
	text-transform: uppercase;
	color: white;
	padding: 5px 10px;
	cursor: pointer;
	transition: filter 300ms;
		&:hover { filter: brightness(1.2) }
`
const BtnAcceptAll = styled(Btn)`
	background: var(--green);
`
const BtnCustomize = styled(Btn)`
	background: transparent;
	border: 1.5px solid;

	border-color: var(--blue);
	color: var(--blue);
	
	display: flex;
	align-items: center;
	gap: 0.4em;
		img.inline-icon {
			height: 21px;
		}
`
const BtnAcceptSelection = styled(Btn)`
	background: transparent;
	border: 1.5px solid;
	border-color: var(--green);
	color: var(--green);
`
const CookieMessage = styled.div`

/* 	--main_CM_color: #524763;
	--button_color: #3e2c59;
	--link_color: #ff9000; */

	padding: 0;
	margin: 0;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: hidden;
	display: flex;
	background-color: rgba(0,0,0, .5); /* dimmed light on the entire body background */

`



