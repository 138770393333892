/* check https://www.npmjs.com/package/react-scroll-up */

import React from 'react'
import styled from '@emotion/styled'
import { FaChevronCircleUp } from 'react-icons/fa';
import { useSetButtonVisibleAt } from './customHookButton'

const BottoneTop = styled.button`
  display: block; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 95px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  cursor: pointer; /* Add a mouse pointer on hover */
  font-size: 45px; /* Increase font size */
	background-color: transparent;
	color: #383058;
	
	animation: fadeIn 1s;
`

const ButtonScrollUp = () => {

	const { isVisible, goTo } = useSetButtonVisibleAt(450)

	return (
		isVisible 
			? <BottoneTop onClick={() => goTo()} className="BottoneTop"> <FaChevronCircleUp /> </BottoneTop> 
			: null
	)
}
export default ButtonScrollUp