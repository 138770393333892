import React from "react";
import styled from "@emotion/styled"
import ULMenu from "./listaMenu";

const MenuMobileSticky = styled.div`
	position: sticky;
	bottom: 0;
	padding: 7px 0;

	background-color: #524763; 
	z-index: 2;
	
	display: flex;
	overflow: hidden;
`

const MenuMobile = () => (
		<MenuMobileSticky className="menu-mobile">
			<ULMenu />
		</MenuMobileSticky >
)
export default MenuMobile